import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Box, Stack } from '@mui/material';
import { formatDateMonth } from '../../../utils/utils';

function CardNews(props) {
    const { news, settings } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                backgroundColor: 'rgba(67,91,134,1)',
                backdropFilter: 'blur(8px)',
                color: 'white',
                borderRadius: 0.5,
                padding: '20px',
                boxShadow: '2px 2px 3px rgba(0,0,0,0.6)'
            }}
        >
            <Stack
                spacing={1}
                minHeight='300px'
                maxHeight='390px'
                height={'100%'}
                sx={{
                    width: '100%',
                    marginTop: '10px',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    '&::-webkit-scrollbar': {
                        width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(255,255,255,0.9)',
                        borderRadius: '20px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        borderRadius: '20px',
                    }
                }}
            >
                {news.sort((a, b) => new Date(b.date) - new Date(a.date)).map((newItem, index) => (
                    <Box
                        key={index}
                        padding={2}
                        borderRadius={0.5}
                        sx={{
                            width: '98%',
                            bgcolor: 'rgba(221,221,221,0.2)',
                            textAlign: 'left',
                            textShadow: '0 0 3px rgba(0,0,0,0.4)',
                            '&:hover': {
                                bgcolor: theme => theme.palette['green'].main,
                            },
                        }}
                    >
                        <Typography variant='caption' color='white'>
                            {formatDateMonth(newItem.date)}
                        </Typography>
                        <Typography variant='subtitle1' color='white' sx={{ lineHeight: '0.8' }}>
                            {newItem['title_' + settings.language]}
                        </Typography>
                        <Typography
                            variant='body1'
                            color='white'
                            textAlign='left'
                            sx={{
                                display: 'block',
                                lineHeight: 1,
                                marginTop: 1,
                                fontWeight: 400,
                            }}
                        >
                            {newItem['description_' + settings.language]}
                        </Typography>
                    </Box>
                ))}
            </Stack>
        </Box>
    );
};
CardNews.propTypes = {
    news: PropTypes.array
};
const mapStateToProps = (state) => ({
    settings: state.settings
});
export default connect(mapStateToProps)(CardNews);